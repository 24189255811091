/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider.state('premier', {
    parent: 'root',
    url: '/premier',
    templateUrl: 'sections/premier-plan/templates/acp-premier-plan.ng.html',
    data: {
      permissions: {
        only: 'oac4PremierEnabled',
        redirectTo: {
          oac4PremierEnabled: 'dashboard'
        }
      }
    },
    resolve: {
      pageTitle: /* @ngInject */ function(module, $injector) {
        return $injector.invoke(
          nsUtilProvider.getPageTitle('sections/premier-plan')
        );
      },
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.premier-plan" */ 'sections/premier-plan').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
